import React from "react";
import * as styles from "./GetADemo.module.scss";
import cn from "classnames";
import { Typography, TypographyV2 } from "..";
import {
	CreateFreeAccountButton,
	DemoButtonWithInput,
} from "@components/Button_new/Button";
import { Colors } from "@helpers/Colors";
import classNames from "classnames";

type GetADemoProps = {
	titleText: string;
	titleColor?: Colors;
	subtitleText: string;
	subTitleColor?: Colors;
	imageNode: React.ReactNode;
	linkColor?: "marine" | "medium_pink" | "ui_01";
	inputLightMode?: boolean;
	titleWidth?: number;
	subtitleWidth?: number;
	showSignUp?: boolean;
	showSignUpFreeButton?: boolean;
	getADemoOverride?: string;
	leftSectionOverride?: string;
	rightSectionStylesOverride?:string;
	subtitleStylesOverride?: string;
	titleStylesOverride?: string;
	buttonContainerOverride?: string;

};
export const GetADemo = ({
	titleText,
	titleColor,
	subtitleText,
	subTitleColor = 'ui-01',
	imageNode,
	linkColor = "marine",
	inputLightMode,
	titleWidth = 567,
	subtitleWidth = 567,
	showSignUp = false,
	showSignUpFreeButton = false,
	getADemoOverride,
	leftSectionOverride,
	rightSectionStylesOverride,
	subtitleStylesOverride,
	titleStylesOverride,
	buttonContainerOverride
}: GetADemoProps) => {
	const isBioTech =
		titleText === "Optimize industrial biotech research and development";

	const titleStyles = isBioTech ? styles.titleBioTech : styles.title;

	const subtitleStyles = isBioTech ? styles.subtitleBioTech : styles.subtitle;

	return (
		<div className={cn(styles.getADemo, getADemoOverride)}>
			<div
				className={cn(styles.leftSection, leftSectionOverride )}
			>
				<TypographyV2
					variant="HEADING_1"
					color={titleColor}
					className={cn(titleStyles, titleStylesOverride)}
					style={{ maxWidth: titleWidth }}
				>
					{titleText}
				</TypographyV2>
				{showSignUp ? (
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_LARGE"
						weight="REGULAR"
						color={subTitleColor}
						className={cn(subtitleStyles, subtitleStylesOverride)}
						style={{ maxWidth: subtitleWidth }}
					>
						{subtitleText}
					</TypographyV2>
				) : (
					<Typography
						variant="SUBTITLE"
						color={subTitleColor}
						className={cn(styles.subtitle, subtitleStylesOverride)}
						style={{ maxWidth: subtitleWidth }}
					>
						{subtitleText}
					</Typography>
				)}
				<div
					className={cn(styles.buttonContainer, {
						[styles.centeredBioTech]: isBioTech
					}, buttonContainerOverride)}
				>
					<div>
						{/* this is the culprit */}
						<DemoButtonWithInput
							lightmode={inputLightMode}
							showSignUp={showSignUp}
							showSignUpFreeButton={showSignUpFreeButton}
						/>
					</div>
					{!showSignUp && (
						<CreateFreeAccountButton color={linkColor} />
					)}
				</div>
			</div>
			<div className={classNames(rightSectionStylesOverride)}>{imageNode}</div>
		</div>
	);
};
